@import "src/styles/variabels";

.MuiChip-root {
  background-color: #f4f9f3 !important;
}


.date-multiple_wrapper {
  max-width: 29rem;
  width: 29rem;

  .MuiPickersBasePicker-containerLandscape {
    flex-direction: column;
  }

  .MuiPickersToolbar-toolbarLandscape {
    max-width: inherit;
  }

  .MuiPickersToolbar-toolbar {
    background: #F9F9F9;
    height: 7rem;
    min-height: 4rem;
    border-bottom: 0.1px solid $color-border;
  }

  .MuiPickersDataRangeToolbar-controls {
    margin-bottom: 0.8rem;
  }

  .MuiPickersDataRangeToolbar-title {
    color: #000;
    font-size: 1.6rem;
  }

  .MuiPickersStaticWrapper-staticWrapperRoot {
    min-width: 29rem;
  }

  .MuiTabs-root {
    min-height: 3.8rem;
    border-bottom: 0.1px solid $color-border;

    .MuiTab-root {
      min-height: 3.8rem;

      &.Mui-selected {
        font-weight: 600;
      }
    }
  }

  .tabs-content {
    .MuiPickersBasePicker-pickerView {
      max-width: inherit;
      min-height: 26rem;
      min-width: 29rem;
    }

    .MuiPickersCalendar-transitionContainer {
      min-height: 17rem;

      .MuiIconButton-root {
        height: 2.6rem;
        min-width: 3.6rem;
      }
    }

    .MuiTypography-caption {
      width: 3.8rem;
    }

  }

  .controls {
    padding: 1rem;
    border-top: 0.1px solid $color-border;
  }
}



