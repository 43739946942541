@import './variabels';

.data-grid {
  padding: 1.6rem;
  .data-grid-wrapper {
    border-color: $color-border;
    border-radius: 0;
    padding: 1rem;
  }
  .toolbar-head {
    padding: 0 0 1rem;
    .title {
      font-weight: bold;
    }
    .toolbar-controls li {
      button {
        height: 3.6rem;
        min-width: 12rem;
      }
    }
  }
  &__toolbar {
    display: flex;
    background: #f9f9f9;
    padding: 0.6rem 1.6rem 1.6rem;
    margin: 0 0 1.6rem;
    justify-content: space-between;
    &__controls {
      display: none;
    }
    &-button {
      padding: 1.9rem 0 0;
    }
  }
  .toolbar-filters {
    align-items: flex-start;
    margin: -0.8rem -0.8rem 0;
    padding: 0;
    > * {
      padding: 0.8rem 0.8rem 0;
      margin: 0;
      align-self: flex-end;
    }
    svg {
      color: $color-primary;
    }
    .control {
      margin-left: auto;
      margin-right: 0;
    }
  }
  .data-grid-scroll {
    min-height: 20rem;
    &:hover {
      & > :nth-child(2) {
        z-index: 22;
      }
    }
    table {
      thead tr th {
        color: #8a8f91;
        font-weight: 500;
        line-height: 2.4rem;
        white-space: nowrap;
        padding: 0.5rem 0.8rem;
        &:first-child {
          padding-left: 0.5rem;
        }
      }
      tbody {
        tr {
          td {
            padding: 0.5rem 0.8rem;
            &:first-child {
              padding-left: 0.5rem;
              &.is-edit-allow {
                padding-left: 1rem;
              }
            }
            &.swap {
              padding: 0;
              .swap-wrap {
                margin: -5px -5px -5px 0;
              }
            }
          }
        }
      }
    }
  }
  .data-grid-pagination {
    padding-top: 1rem;
    color: $color-table;
    flex-wrap: wrap;
    .MuiFormControl-root {
      max-width: 8rem;
    }
    .size .total {
      padding-left: 1.6rem;
      span {
        font-weight: 600;
      }
    }
    .pagination {
      .disabled {
        display: none;
      }
      li {
        flex-shrink: 0;
        a {
          color: #95a2a7;
          padding: 0.3rem 0.5rem;
          border-radius: 50%;
          min-width: 3.6rem;
          &:hover {
            color: $color-primary;
          }
        }
        &.active a {
          box-shadow: none;
          background: $color-primary-light;
          min-width: 3.6rem;
          color: #fff;
        }
      }
    }
  }
  .data-grid-row-controls {
    align-items: center;
  }
}
.grid-inner {
  padding: 0;
  height: 100%;
  .data-grid-wrapper {
    padding: 0;
    border: none;
  }
  .toolbar-head {
    display: none !important;
  }
}
.grid-toolbar-filters {
  .toolbar-head {
    top: 2.5rem;
    right: 2rem;
    position: absolute;
  }
}
.data-grid-wrapper-style > .grid-footer > .pagination > li > a {
  &:hover,
  &.active {
    color: $color-secondary;
  }
}

.data-grid {
  &__pagination-item {
    &_active {
      color: $color-secondary;
    }
  }
}

.toolbar-controls {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .data-grid {
    padding: 0;
    &__toolbar {
      padding: 1.6rem;
      &-button {
        padding: 0.3rem 0;
      }
    }
    .data-grid-wrapper {
      border: none;
      padding: 0;
    }
    .toolbar-filters {
      margin: 0;
      > * {
        width: 100% !important;
        padding: 0;
        align-self: flex-start;
      }
      .control {
        margin-bottom: 1rem;
      }
    }
    .toolbar-head {
      display: block;
      padding: 1rem;
      .title {
        font-size: 1.8rem;
      }
      .toolbar-controls {
        margin: 0.5rem -0.5rem 0;
        justify-content: space-between;
        li {
          padding: 0 0.5rem;
          flex-grow: 1;
          button {
            min-width: 100%;
            width: 100% !important;
            line-height: 1.2;
            font-size: 1rem;
            height: 2.8rem;
          }
        }
      }
    }
    .data-grid-pagination {
      padding: 1rem;
      flex-direction: column;
      > * {
        &:first-child {
          padding-top: 0;
        }
      }
      .pagination {
        padding-top: 0.5rem;
        li {
          line-height: 2rem;
          a {
            min-width: 2.6rem;
          }
          &.active {
            a {
              min-width: 2.6rem;
            }
          }
        }
      }
      .size {
        .total {
          padding-left: 1rem;
        }
        .MuiInputBase-root {
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;
        }
      }
    }
    .data-grid-row-controls {
      flex-wrap: wrap;
      justify-content: center;
      button {
        font-size: 1rem;
      }
    }
    .data-grid-scroll {
      min-height: 30rem;
      table tbody tr td {
        min-width: 5rem;
      }
    }
  }
  .toolbar-filters__mobile-filters {
    .MuiPopover-paper {
      padding: 0.6rem 1.6rem 0.1rem;
    }
  }
}

@media (max-width: map-get($breakpoints, 'sm') - 1 + px) {
  .data-grid {
    &__toolbar {
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      display: grid;
      grid-gap: 0.6rem;
      justify-content: stretch;
    }
    &-button {
      padding: 0;
    }
  }
  .toolbar-filters__mobile {
    button {
      width: 100%;
    }
  }
  .toolbar-controls {
    justify-content: center;
  }
}
