@import 'variabels';

a {
  color: $color-secondary;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

body {
  overflow: hidden;
  position: relative;
}

body[dir='rtl'] {
  .data-grid-pagination {
    .previous,
    .next {
      transform: rotate(180deg);
    }
  }
}

body,
html {
  height: calc(var(--vh, 1vh) * 100);
}

#root {
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  //.root-drawer .logo svg {
  //  transform: translateX(-.8rem);
  //
  //  &:nth-child(2) {
  //    transform: translateX(1.2rem);
  //  }
  //}
  //
  //.root-drawer.opened .logo,
  //.root-drawer.hover .logo {
  //  svg:nth-child(1) {
  //    transform: translateX(0);
  //  }
  //
  //  svg:nth-child(2) {
  //    transform: translateX(2rem);
  //  }
  //}
  //.root-content {
  //  position: relative;
  //  overflow: hidden;
  //  display: flex;
  //  flex: 1 auto;
  //  z-index: 1;
  //
  //  .root-page-content {
  //    background: #fff;
  //    position: relative;
  //  }
  //}
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  body {
    overflow: visible;
  }
  #root {
    .root-content {
      overflow: visible;
    }
  }
}
