@import 'src/styles/variabels';

.wrap {
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1 1 auto;
  z-index: 1;
}
.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fff;
}
@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .wrap {
    flex-direction: column;
    overflow: visible;
  }
}
