$color-primary: #5fc0eb;
$color-primary-light: #21526c;
$color-secondary: #21526c;
$color-secondary-light: rgba($color-secondary, 0.05);

$color-default: #434343;
$color-border: #eeeeee;
$color-table: #888d8f;

$color-dark-grey: #333;
$color-dusty-grey: #999;

$drawer-width-open: 25.6rem;
$drawer-width-close: 6.4rem;

$breakpoints: (
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
);

:export {
  @each $key, $value in $breakpoints {
    #{unquote($key)}: $value;
  }
}

:export {
  colorPrimary: $color-primary;
  colorPrimaryLight: $color-primary-light;
  colorSecondary: $color-secondary;
  colorSecondaryLight: $color-secondary-light;
  colorDefault: $color-default;
  colorBorder: $color-border;
  colorTable: $color-table;

  drawerWidthOpen: $drawer-width-open;
  drawerWidthClose: $drawer-width-close;
}
