@import 'variabels';
.image-uploader{
  .MuiSvgIcon-colorSecondary,
  .MuiButton-textSecondary{
    color: $color-primary
  }
}
.image-dialog .MuiDialog-paper {
  max-width: 80rem;
  canvas {
    width: 100% !important;
    height: auto !important;
  }
}
.image-uploader-wrapper .thumb img{
  width: 100%
}
.preview-item{
  .value{
    min-height: 3.2rem;
  }
  .image-tooltip{
    div{
      min-width: 4rem;
      min-height: 4rem;
    }
  }
}
.image-uploader-contains{
  .image-uploader-wrapper{
    .thumb{
      img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
.image-tooltip{
  .avatar,
  .no-image {
    height: 25px !important;
    width: 25px !important;

  }
  .no-image  {
    svg {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
}

.user-nav_drop-down {
  .user-nav_drop-down-content {
    .MuiButtonBase-root {
      min-height: 3.9rem;
      .MuiButton-label {
        font-size: 1.4rem;
      }
    }
  }
}
